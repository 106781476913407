import { CONSTANTS } from './constants'
import { getCurrentUrl, parseParams, isClickRequest, toggleDebugging, delay, cruiseToDom } from './utils/helpers'
import { bootSession } from './bootsession'
import { bootAnalytics } from './rodeoevents'
import { RodeoAnalytics } from './utils/rodeoanalytics'
import getBrowserInfo from './utils/browerdetections'
import { handleCruiseProfile } from './utils/cruiseprofile'
import { RodeoJS } from './utils/rodeojs'
import { loadScripts } from './utils/loadscript'
import { logRodeoVersion } from './utils/logger'
import { publishRodeoLoggedInEvent } from './utils/customevents'
import { getState, setState } from './utils/storage'
import { syncSession } from './utils/session'
;(function (window, document, location, history, localStorage) {
  // Debug message - only show for oatsdev
  if (window.location.hostname && window.location.hostname.includes('oatsdev')) {
    console.error('🔍 CRUISE-CONTROL SCRIPT LOADED - VERSION 3/21/2025')
  }

  // Check if the script has already been executed
  if (window[CONSTANTS.WINDOW_KEYS.SCRIPT_LOADED]) {
    console.error('⚠️ Script already executed once - exiting')
    return // Exit the script if it has already been executed
  }

  // Set a flag indicating that the script has been executed
  window[CONSTANTS.WINDOW_KEYS.SCRIPT_LOADED] = true
  window.addEventListener('message', event => {
    console.log('Message from worker rodeo:', event.data)
    if (event.eventType == 'SHOPIFY_EVENT') {
      // Add validation to check the origin if necessary
      console.log('Message from worker rodeo:', event.data)
    }
  })

  // Define the main execution logic as an async function
  const main = async () => {
    // expose globally so the can be used via window
    window.RodeoJS = RodeoJS
    window.RodeoAnalytics = RodeoAnalytics

    const CURRENT_URL = getCurrentUrl()
    const CURRENT_PARAMS = parseParams(CURRENT_URL)
    const CURRENT_PROFILE = window[CONSTANTS.WINDOW_KEYS.PROFILE]
    const CURRENT_SHOP = CURRENT_PROFILE.shop

    // Debug display URL parameters - only for oatsdev
    if (CURRENT_URL.hostname && CURRENT_URL.hostname.includes('oatsdev')) {
      console.error('📍 PAGE LOAD PARAMETERS:')
      console.error('URL:', CURRENT_URL.href)
      console.error('Parsed parameters:', CURRENT_PARAMS)
      console.error('Has cruise param:', !!CURRENT_PARAMS.cruise)
      console.error('Has utm_pid param:', !!CURRENT_PARAMS.utm_pid)
    }

    const API_DOMAIN = CURRENT_SHOP.domain || CURRENT_SHOP.permanent_domain || CURRENT_URL.host
    const ENDPOINT = `${CURRENT_URL.protocol}//${API_DOMAIN}${window[CONSTANTS.WINDOW_KEYS.PATH]}`
    const SIGNED_IN = !CURRENT_PROFILE.is_visitor
    const IS_CLICK = isClickRequest(CURRENT_PARAMS)
    const SCRIPT_URLS = [CONSTANTS.API_ENDPOINTS.RODEO_VISTOR_SCRIPT]

    logRodeoVersion()
    // load rodeo-visitor.js
    // await loadScripts(SCRIPT_URLS, {
    //   maxRetries: 3, // Retry up to 3 times
    //   maxDelay: 1000, // Base retry delay of 1 second
    //   retryLimit: 3000 // Max delay of 3 seconds
    // })
    /**
     * DEBUGGING
     */
    window.cruise_debugging = toggleDebugging

    // Start the boot session
    try {
      await bootSession(SIGNED_IN, CURRENT_PROFILE, IS_CLICK, CURRENT_PARAMS, CURRENT_URL)
    } catch (error) {
      console.error(CONSTANTS.MESSAGES.AUTH_CALLBACK_ERROR, error)
    }

    bootAnalytics()

    if (getState(CONSTANTS.STORAGE_KEYS.AUTH_REDIRECT)) {
      await delay(2000)
      publishRodeoLoggedInEvent()
      setState(CONSTANTS.STORAGE_KEYS.AUTH_REDIRECT, null)
    }
    syncSession()

    if (window.cruise_profile) {
      if (window.rodeo_data_attributes) {
        try {
          await cruiseToDom(window.cruise_profile)
        } catch (error) {
          console.error('Error in cruiseToDom:', error)
        }
      } else {
        console.log(`Key "${keyToCheck}" not found in localStorage. Rendering skipped.`)
      }
    }

    getBrowserInfo().then(async result => {
      if (!result.isPrivate) {
        await delay(3000) // Wait for 3 seconds added
        handleCruiseProfile()
      } else {
        console.warn('private mode detected, skipping profile handling.')
      }
    })
  }

  // Call the async function
  main()
})(window, document, location, history, localStorage)
